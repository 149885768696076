import React from "react";
import "./Pros.css";
import ProsCard from "./ProsCard.jsx";

const Pros = () => {
  let pros = [
    {
      id: 1,
      imgPath: "./card-robot.png",
      imgHover: "./card-robot-light.png",
      title: "GET A TELEBOTZ",
      text: "Our Telebotz helps you save your energy and expense to travel to important events!",
    },
    {
      id: 2,
      imgPath: "./card-money.png",
      imgHover: "./card-money-light.png",
      title: "RENT",
      text: "Easy renting service to enable your distant interactions simple and accessible.",
    },
    {
      id: 3,
      imgPath: "./card-console.png",
      imgHover: "./card-console-light.png",
      title: "OPERATE YOUR BOT",
      text: "Operate your Telebotz from any location with the help of our dedicated webapp.",
    },
    {
      id: 4,
      imgPath: "./card-spanner.png",
      imgHover: "./card-spanner-light.png",
      title: "MAINTENANCE AND REPAIR",
      text: "We will provide all sorts of maintenance and repair for your Telebotz.",
    },
    {
      id: 5,
      imgPath: "./card-feedback.png",
      imgHover: "./card-feedback-light.png",
      title: "FEEDBACK",
      text: "We care at most about the feedback that we receive from our customers",
    },
    {
      id: 6,
      imgPath: "./card-sub.png",
      imgHover: "./card-sub-light.png",
      title: "BUY SUBSCRIPTION",
      text: "One simple subscription helps you attend and conduct various events.",
    },
  ];

  return (
    <>
      <div className="pros-head-container" id="features">
        <h1 className="pros-head text-4xl md:text-7xl hero-text font-montserrat font-semiBold">
          Why <span className="text-red-500 font-montserrat">Choose</span> Us?
        </h1>
        <p className="pros-text font-montserrat font-medium">
          we believe in delivering more than just products; we offer solutions
          tailored to your unique needs.
        </p>
      </div>
      <div className="pros-cards-container px-8 md:px-48">
        {pros.map((item) => {
          return (
            <ProsCard
              key={item.id}
              imgPath={item.imgPath}
              imgHover={item.imgHover}
              title={item.title}
              text={item.text}
            />
          );
        })}
      </div>
    </>
  );
};

export default Pros;
