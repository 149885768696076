import React from "react";
import "./Navbar.css";
import "./Footer.css";

const Footer = () => {

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const navbarHeight = document.querySelector(".navbar")?.offsetHeight || 80; // Adjust if needed
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: sectionPosition - navbarHeight - 20, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="footer-container">
        <a href onClick={() => scrollToSection("home")}>
          <img className="ct-logo-footer" src="./ct_logo.png" alt="CT logo" />
        </a>
        <div className="space-x-24 font-montserrat font-medium">
          <a href onClick={() => scrollToSection("home")} className="footer-item font-montserrat font-medium">
            Home
          </a>
          <a
            href onClick={() => scrollToSection("showcase")}
            className="footer-item font-montserrat font-medium"
          >
            Product
          </a>
          <a href onClick={() => scrollToSection("about")} className="footer-item font-montserrat font-medium">
            About Us
          </a>
          <a href onClick={() => scrollToSection("contact")} className="footer-item font-montserrat font-medium">
            Contact Us
          </a>
        </div>
        <div className="socials">
          <a
            href="https://www.linkedin.com/company/crobottech/posts/?feedView=all"
            target="_blank"
          >
            <img src="./linkedin-42.png" alt="" />
          </a>
          <a
            href="mailto:crobottechservices@gmail.com"
            target="_blank"
          >
            <img src="./gmail-36.png" alt="Email Us" />
          </a>
          <a
            href="https://www.instagram.com/crobot__tech?igsh=ejd0aXJ2NjIzdXBq"
            target="_blank"
          >
            <img src="./instagram.png" alt="" />
          </a>
          <a
            href="https://youtube.com/@manigandanns9400?si=YQrWqMXRUmXep2uz"
            target="_blank"
          >
            <img src="./youtube.png" alt="" />
          </a>
        </div>
        <p className="footer-text font-montserrat">We Care and We Assist!</p>
      </div>
      <div className="footer-after">
        <p className="footer-address footer-address-head font-montserrat font-medium">
          Mailing Address
        </p>
        <p className="footer-address lg:w-1/2 font-montserrat font-medium">
          Crobot Technologies Private Limited No: 68, Kadamban Street, Madurai
          Shree Meenatchi Nagar, Valasaravakkam, Chennai, Tamil Nadu, India -
          600087.
        </p>
        <p className="footer-copyrights font-montserrat font-medium">
          COPYRIGHTS © 2023 CROBOT TECHNOLOGIES PVT. LTD.
        </p>
      </div>
    </>
  );
};

export default Footer;
