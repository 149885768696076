import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <div className="aboutUs-head font-montserrat font-semibold" id="about">
        About <span className="text-red-500 font-montserrat">Us</span>
      </div>

      <div className="aboutUs-container px-8 md:px-32 xl:px-48">
        <div className="aboutUs-container-left">
          <h2 className="aboutUs-left-head">
            <div>CROBOT </div>
            Technologies Private Limited (CTPL) is a{" "}
            <span className="text-red-500 font-montserrat font-semibold">
              startup
            </span>{" "}
            company founded in{" "}
            <span className="text-red-500 font-montserrat font-semibold">
              2021
            </span>
            .
          </h2>
          <p className="aboutUs-left-text font-montserrat font-medium py-5">
            with an aim to provide automation solutions in the area of robotics.
            CTPL have a line of products in the area of mobile robots for
            various applications.
          </p>
          <button className="aboutUs-left-btn font-roboto font-light">
            Know More :)
          </button>
        </div>
        <div className="aboutUs-container-right">
            <div className="aboutUs-right-up">
                <img src="./AboutUs.png" alt="AboutUs_image" />
            </div>
            <div className="aboutUs-right-down">
                <div className="aboutUS-items">
                    <p className="font-montserrat font-semibold text-red-500 aboutUs-items-value">4</p>
                    <p className="font-montserrat font-medium aboutUs-items-desc">Years Of Experience</p>
                </div>
                <div className="aboutUS-items">
                    <p className="font-montserrat font-semibold text-red-500 aboutUs-items-value">50 +</p>
                    <p className="font-montserrat font-medium aboutUs-items-desc">Happy Clients</p>
                </div>
                <div className="aboutUS-items">
                    <p className="font-montserrat font-semibold text-red-500 aboutUs-items-value">23</p>
                    <p className="font-montserrat font-medium aboutUs-items-desc">Completed Projects</p>
                </div>
                <div className="aboutUS-items">
                    <p className="font-montserrat font-semibold text-red-500 aboutUs-items-value">100 +</p>
                    <p className="font-montserrat font-medium aboutUs-items-desc">Goals</p>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
