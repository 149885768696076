import React, { useRef, useState } from "react";
import "./QA.css";
import QACard from "./QACard.jsx";
import emailjs from "emailjs-com";
const QA = () => {
  const nameRef = useRef(null);
  const mailRef = useRef(null);
  const queryRef = useRef(null);
  const [isSending,setIsSending] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    mail: "",
    query: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Submitted with Data: ", formData);

    e.preventDefault();
    setIsSending(true);
    
    const emailParams = {
      name: formData.name,
      email: formData.mail,
      message: formData.query,
    };
    try {
      await emailjs.send(
        "service_i587san",   // Replace with your EmailJS Service ID
        "template_i4uj8cr",  // Replace with your EmailJS Template ID
        emailParams,
        "j6YV8vGvd-rkoUanv"       // Replace with your EmailJS User ID
      );

      setMessage("Query sent successfully! ✅");
      setFormData({ name: "", mail: "", query: "" });
    } catch (error) {
      console.error("Email sending failed:", error);
      setMessage("Failed to send query. ❌");
    } finally {
      setIsSending(false);
    }

  };

  const data = [
    {
      id: 1,
      img: "/q1.png",
      question:
        "Hello Mani! What do you feel, is the role of robots in human life?",
    },
    {
      id: 2,
      img: "/q2.png",
      question:
        "UBot is one of the products listed, can you elaborate on its application?",
    },
    {
      id: 3,
      img: "/q1.png",
      question: "What inspired you to create UBot?",
    },
    {
      id: 4,
      img: "/q2.png",
      question: "What are the future plans for UBot's development?",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % data.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? data.length - 2
        : (prevIndex - 2 + data.length) % data.length
    );
  };

  return (
    <>
      <div className="qa-container px-8 md:px-16 xl:px-48" id="contact">
        <div className="qa-form-container w-full md:w-2/5 ">
          <div className="py-12 bg-white rounded-lg space-y-4">
            <h1 className="qa-head text-4xl font-montserrat font-semibold text-black">
              Have{" "}
              <span className="font-montserrat font-semibold text-red-500">
                Questions
              </span>
              ?
            </h1>
            <p className="qa-text w-4/5 font-montserrat font-medium text-gray-600">
              Chat with Our Founder & Clear Your Doubts!!
            </p>
            <form className="space-y-4 w-4/5" onSubmit={handleSubmit}>
              <div>
                <input
                  placeholder="name"
                  ref={nameRef}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-red-300"
                />
              </div>
              <div>
                <input
                  placeholder="email"
                  ref={mailRef}
                  type="email"
                  name="mail"
                  value={formData.mail}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-red-300"
                />
              </div>
              <div>
                <textarea
                  placeholder="Enter your query"
                  ref={queryRef}
                  type="text"
                  name="query"
                  value={formData.query}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-red-300"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-orange-500 to-red-500 text-white py-2 rounded-md shadow-md font-medium"
              >
                {isSending ? "Sending..." : "Connect!"}
              </button>
            </form>
          </div>
        </div>
        <div className="qa-cards-container w-full md:w-3/5 flex flex-row gap-5 items-center">
            <button
              onClick={prevSlide}
              className="px-4 py-2 bg-gray-200 rounded-full shadow hover:bg-gray-300"
            >
              {"<"}
            </button>
            <div className="flex space-x-4">
              {data.slice(currentIndex, currentIndex + 2).map((item) => (
                <QACard key={item.id} {...item} />
              ))}
            </div>
            <button
              onClick={nextSlide}
              className="px-4 py-2 bg-gray-200 rounded-full shadow hover:bg-gray-300"
            >
              {">"}
            </button>
          </div>
      </div>
    </>
  );
};

export default QA;
