import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <>
      <div className="flex flex-col-reverse md:flex-row items-start justify-between px-8 md:px-48 py-8 md:py-16" id="home">
        <div className="text-center md:text-left w-full md:w-1/2 space-y-6">
          <h1 className="text-4xl md:text-7xl hero-text font-montserrat font-semiBold">
            Introducing you to our{" "}
            <span className="text-red-500 font-montserrat">first</span>{" "}
            successful product.
          </h1>
          <p className="text-gray-600 font-montserrat w-3/4 font-medium hero-p">
            A telepresence mobile robot with semi-autonomous / autonomous
            navigation capabilities.
          </p>
          <div className="flex space-x-4 justify-center md:justify-start py-10">
            {/* <button className="font-roboto font-regular hero-btn bg-gradient-to-r from-orange-500 to-red-500 text-white px-6 py-3 rounded-md shadow-md">
              Get Started
            </button> */}
            <button className="font-roboto font-regular hero-btn border-2 border-red-500 text-red-500 px-6 py-3 rounded-md shadow-md">
              Learn More
            </button>
          </div>
        </div>
        <img src="./cute-robot.png" alt="Robot" className="w-full md:w-1/2" />
      </div>
    </>
  );
};

export default Hero;
