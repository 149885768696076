import React from "react";
import Navbar from "../components/Navbar.jsx";
import Hero from "../components/Hero.jsx";
import AboutUs from "../components/AboutUs.jsx";
import Footer from "../components/Footer.jsx";
import Pros from "../components/Pros.jsx";
import Showcase from "../components/Showcase.jsx";
import QA from "../components/QA.jsx";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Pros />
      <Showcase />
      <AboutUs />
      <QA />
      <Footer />
    </div>
  );
};

export default Home;
