import React from "react";
import "./QA.css";

const QACard = ({ id, img, question }) => {
  return (
    <div className="relative p-1 w-1/2">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="qa-card-id font-montserrat font-semibold text-red-500 text-xl">
          Q{id}
        </div>
        <img
          src={img}
          alt={`Q${id}`}
          className="qa-card-img w-full h-64 object-cover"
        />
        <div className="p-4">
          <p className="qa-card-text font-montserrat font-medium text-gray-600">
            {question}
          </p>
        </div>
      </div>
    </div>
  );
};

export default QACard;
