import React from "react";
import "./Navbar.css"

const Navbar = () => {

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const navbarHeight = document.querySelector(".navbar")?.offsetHeight || 80; // Adjust if needed
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: sectionPosition - navbarHeight - 20, behavior: "smooth" });
    }
  };
  
  return (
    <nav className="px-5 py-4 navbar w-full sticky top-0 z-50 bg-white">
      <div className="container mx-auto flex justify-between items-center">
        <a href onClick={() => scrollToSection("home")} className="ct-logo"><img src="./ct_logo.png" alt="CT logo" /></a>
        <div className="space-x-12 font-montserrat font-bold">
          <a href onClick={() => scrollToSection("home")} className="nav-item">Home</a>
          <a href onClick={() => scrollToSection("showcase")} className="nav-item">Product</a>
          <a href onClick={() => scrollToSection("about")} className="nav-item">About Us</a>
          <a href onClick={() => scrollToSection("contact")} className="nav-item">Contact Us</a>
        </div>
        <div>
            <button className="login-btn font-roboto font-regular"> <span><img src="./login-person.png" alt="" /></span> Login / Register</button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;