import React, { useEffect, useState } from "react";

const ProsCard = ({ imgPath, imgHover, title, text }) => {
  const [imgSrc, setImgSrc] = useState(imgPath);

  useEffect(() => {
    console.log(`Image source updated to: ${imgSrc}`);
  }, [imgSrc]);

  return (
    <>
      <div
        className="card pros-card"
        onMouseEnter={() => setImgSrc(imgHover)}
        onMouseLeave={() => setImgSrc(imgPath)}
      >
        <img className="pros-card-img" src={imgSrc} alt="cardImage" />
        <h5 className="pros-card-title font-montserrat font-semibold">
          {title}
        </h5>
        <p className="pros-card-text font-montserrat font-medium">{text}</p>
      </div>
    </>
  );
};

export default ProsCard;
