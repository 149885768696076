import React, { useEffect, useState } from "react";
import "./Showcase.css";
import Modal from 'react-modal'

Modal.setAppElement("#root");

const robotSeriesMap = {
  "./crobot-showcase-1.webp": "UBOT",
  "./yellow-bots-lateral.jpg": "Aryan",
  "./main-models.jpg": "Tulip",
  "./new-bot.jpg": "Aryan",
  "./ubot-black.jpg": "UBOT",
  "./yellow-bots.jpg": "Aryan",
  "./all-bots-croped.jpg": "UBOT",
};



const Showcase = () => {

  const seriesData = {
    UBOT: {
      name: "UBOT Series",
      description: "Step into a new era where our UBOT becomes your personal bridge, revolutionizing the way we communicate. Join us in shattering the barriers of distance, unlocking boundless connectivity as we transit into the realm of mixed reality. UBot a two wheeled differential drive mobile robot equipped with telepresence(enables remote users to interact with and control the robot in real time, making it ideal for applications such as remote monitoring and virtual presence), person following, SLAM (Simultaneous Localization and Mapping), and waypoints navigation capabilities.",
      image: "./ubot-white.jpg",
    },
    Aryan: {
      name: "Aryan Series",
      description: "We proudly presents the future of education towards innovation with ARIYAN, the robot designed to enhance experiential learning across four quadrants: Education, Training, Consultancy, and Research. Dive into ARIYAN's programming with any Open-Source Linux based architecture and explore the infinite ready-built libraries available at your fingertips. Learn, experiment, and push the boundaries of your coding skills with ARIYAN. Equipped with state-of-the-art sensors, ARIYAN allows you to interact with and understand the world around you. From basic movements to complex tasks, ARIYAN is ready for any challenge.",
      image: "./yellow-bots.jpg",
    },
    Tulip: {
      name: "Tulip Series",
      description: "Tulip, the compact educational robot, is designed to ignite a passion for robotics and coding in students of all ages. With its user-friendly design and powerful capabilities, Tulip transforms the learning experience, making it engaging, hands-on, and accessible. Our mission is to empower every student to create diverse applications within the field of robotics. Tulip extends learning beyond the limitations of traditional classrooms, making it accessible to all.  We're reshaping the way of students learn robotics and coding, from school to college.",
      image: "./circle-bot.jpg",
    },
  };
  
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState(null);

  const openModal = (imageSrc) => {
    const seriesName = robotSeriesMap[imageSrc];
    if (seriesName) {
      setSelectedSeries(seriesData[seriesName]);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const navbarHeight = document.querySelector(".navbar")?.offsetHeight || 80; // Adjust if needed
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: sectionPosition - navbarHeight - 20, behavior: "smooth" });
    }
  };

  const handleBuyClick = () => {
    closeModal();
    setTimeout(() => {
      scrollToSection("contact")
    }, 300);
  };

  useEffect(() => {
    console.log(selectedSeries,modalOpen)
    if (!modalOpen && selectedSeries !== null) {
      setSelectedSeries(null); // Only update state if it's not already null
    }
  }, [modalOpen,selectedSeries]);

  return (
    <>
      <div className="showcase-container" id="showcase">
        <div className="showcase-head font-montserrat font-semibold">
          Discover Our <p className="text-red-500 font-montserrat">Showcase</p>
        </div>
        <div className="showcase-grid-container">
          <div className="showcase-grid">
          {Object.keys(robotSeriesMap).map((imageSrc, index) => (
              <div
                key={index}
                className={`showcase-item showcase-item-${index + 1}`}
                onClick={() => openModal(imageSrc)}
              >
                <img className="showcase-img" src={imageSrc} alt={robotSeriesMap[imageSrc]} />
                {/* <div className="showcase-overlay">
                  <p className="showcase-text">{robotSeriesMap[imageSrc]} Series</p>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Series Information"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {selectedSeries && (
          <div>
            <button className="modal-close" onClick={closeModal}>
              &times;
            </button>
            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"2vw"}}>
              <img src={selectedSeries.image} alt={selectedSeries.name} className="modal-img" />
              <div className="model-desc"> 
                <h2 style={{textAlign:"center",fontWeight:"bold",fontSize:"40px"}}>{selectedSeries.name}</h2>
                <p style={{marginTop:"2vh",letterSpacing:"1.06px",fontSize:"1.1vw"}}>{selectedSeries.description}</p>
                <button className="modal-btn font-roboto font-regular" onClick={handleBuyClick}>Contact to Buy</button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Showcase;
